#intro {
    background: url(../images/manic.jpg) no-repeat bottom center;
    background-size: contain;
    height: 100%;
    .container {
        height: 100%;
        padding-top: 30%;
        padding-bottom: 35%;

        h1 {
            text-transform: uppercase;
        }

        @media (orientation : landscape) {
            padding-top: 20%;
            h1 {
                margin-bottom: .8rem;
            }
        }

        @media (min-width: $screen-sm) and (orientation : landscape) {
            padding-top: 10%;
        }

        @media (min-width: $screen-sm) and (orientation : portrait) {
            padding-top: 20%;
        }

        @media (min-width: $screen-lg) and (orientation : portrait) {
            padding-top: 10%;
        }
        
    }
}