$screen-xs:                 360px;
$screen-sm:                 480px;
$screen-md:                 720px;
$screen-lg:                 960px;
$screen-xl:                 1600px;


$brand-beige:               #f1ede8;
$brand-grey-lightest:       #EEEEEE;
$brand-grey-light:          #BBBBBB;
$brand-grey-medium:         #888888;
$brand-grey:                #555555;
$brand-grey-dark:           #222222;
$brand-pink-light:          #f5dadf;
$brand-pink-dark:           #f1c4cd;
$brand-red:                 #DE123B;
$brand-primary:             #DE123B;

$social-twitter:            #1db2eb;
$social-linkedin:           #4875B4;
$social-github:             #171516;
$social-soundcloud:         #ff3300;
$social-soundcloud-light:   #ff8800;

$container-max:             960px;
$margin-bottom:             1.2rem;
$column-padding:            15px;

