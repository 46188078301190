#listen {
    background-color: #bbb;

    h2 {
        color: #151515;
    }
}



.song {
    margin-bottom: ($margin-bottom * 3);
    background-color: $brand-grey-dark;

    img {
        width: 100%;
        height: auto;
    }

    h3 {
        color: $brand-grey-light;
        margin-top: $margin-bottom;
        text-transform: uppercase;
        text-align: center;
    }

    .stores {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: block;
            border-bottom: 1px solid $brand-grey-light;

            a {
                display: block;
                padding: $column-padding ($column-padding * 2);
                background-color: white;
                transition: all .2s ease-in-out;
                text-align: center;

                img {
                    max-width: 100%;
                    width: auto;
                    height: 48px;
                }
            }

            a:hover,
            a:active {
                background-color: $brand-beige;
            }
        }
    }
}