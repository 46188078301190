
* {
    box-sizing: border-box;
}

html,
body {
    width: 100%;
    height: 100%;
}

body {
    font-family: 'Spectral', Helvetica, Arial, sans-serif;
    color: $brand-grey-light;
    background-color: #151515;
    line-height: 125%;
}

h1 {
    margin-bottom: $margin-bottom;
    line-height: 125%;
    font-weight: 600;
    font-size: 24pt;
    color: $brand-grey-medium;
    @media (min-width: $screen-md) {
        font-size: 28pt
    }
    @media (min-width: $screen-lg) {
        font-size: 30pt;
    }
    @media (min-width: $screen-xl) {
        font-size: 36pt;
    }
}

h2 {
    margin-bottom: $margin-bottom;
    line-height: 125%;
    font-weight: 600;
    font-size: 10pt;
    text-transform: uppercase;
    letter-spacing: .2rem;
    @media (min-width: $screen-md) {
        font-size: 12pt;
        letter-spacing: .3rem;
    }
}

h3 {
    margin-bottom: $margin-bottom;
    line-height: 125%;
    font-weight: 600;
    font-size: 20pt;
    color: $brand-grey-medium;
    line-height: 120%;
}

h4 {
    margin-bottom: $margin-bottom;
    line-height: 125%;
    font-weight: 600;
    font-size: 8pt;
    text-transform: uppercase;
    letter-spacing: .1rem;
    @media (min-width: $screen-md) {
        font-size: 10pt;
        letter-spacing: .2rem;
    }
}

p,
ul,
ol {
    line-height: 125%;
    margin-bottom: $margin-bottom;
    font-size: 10pt;
    @media (min-width: $screen-xs) {
        font-size: 12pt;
    }
}

ul,
ol {
    padding-left: 1.3rem;
}

li {
    list-style-type: disc;
}

strong {
    font-weight: bold;
}

em {
    font-style: italic;
}

a:link,
a:visited {
    color: $brand-primary;
    text-decoration: none;
}

a:hover,
a:active,
a:focus {
    color: white;
    text-decoration: underline;
}

.containment-padding {
    padding: 10% 2rem;
    @media (min-width: $screen-md) {
        padding-left: 5rem;
        padding-right: 5rem;
    }
}

.container {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    @extend .containment-padding;
    max-width: $container-max;
    overflow: hidden;
    & > h1 {
        margin-bottom: 10%;
        text-align: center;
    }
    & > h1 + h2 {
        margin-top: -5%;
        margin-bottom: 10%;
    }
}

@media (min-width: $screen-md) {
    .container {
        padding-top: 5%;
        padding-bottom: 5%;
        & > h1 {
            margin-bottom: 5%;
        }
        & > h1 + h2 {
            margin-top: -3%;
            margin-bottom: 5%;
        }
    }

    .row {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        margin-left: -$column-padding;
        margin-right: -$column-padding;
    }

    .col-m-6 {
        width: 50%;
        padding-left: $column-padding;
        padding-right: $column-padding;
    }
}

#content {
    height: 100%;
}

.section > .container > h2 {
    margin-bottom: 2rem;
    text-align: center;
}

.btn {
    display: inline-block;
    padding: .7rem 1rem;
    border-width: 3px;
    border-style: solid;
    border-color: transparent;
    text-transform: uppercase;
    transition: all .5s;
    @media (min-width: $screen-lg) {
        padding: 1.2rem 2rem;
    }
}

.btn-outline,
a.btn-outline {
    color: $brand-grey-medium;
    border-color: $brand-grey-medium;
    &:hover,
    &:active {
        color: white;
        background-color: $brand-grey-medium;
    }
}



.aspect-ratio {
    position: relative;
    width: 100%;
    height: 0;
    margin: 0 auto;
    padding: 0 0 56% 0;

    iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
}



.embedded-video {
    margin-bottom: $margin-bottom;
}



.actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    a {
        margin: 5px;
        @media (min-width: $screen-lg) {
            margin: 10px;
        }
    }
}


.social {
    display: inline-block;
    background-color: transparent;
    border-radius: 100%;
    width: 44px;
    height: 44px;
    padding: 7px;
    transition: all .5s;
    img {
        display: inline-block;
        opacity: .55;
        transition: all .5s;
        max-width: 24px;
        height: auto;
    }
    @media (min-width: $screen-sm) and (orientation : portrait) {
        width: 68px;
        height: 68px;
        padding: 10px;
        img {
            max-width: 48px;
        }
    }
    @media (min-width: $screen-lg) and (orientation : landscape) {
        width: 68px;
        height: 68px;
        padding: 10px;
        img {
            max-width: 48px;
        }
    }
    &:hover,
    &:active {
        background-color: $brand-grey-medium;
        img {
            opacity: 1;
        }
    }
}

